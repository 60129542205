import React from "react";
import SEO from "../components/seo";
import { Styled404Page } from "./404.styles";
import { graphql, useStaticQuery } from "gatsby";
import MenuRenderer from "../navigation/menu-renderer";
import { NotFoundQueryQuery } from "../../gatsby-graphql";

const NotFoundPage:React.FC<any> = () => {
  const data = useStaticQuery<NotFoundQueryQuery>(graphql`
  query NotFoundQuery {
    allWpMenuItem(filter: { locations: { eq: SITEMAP } } ) {
      nodes {
        ...MenuItemsFragment
      }
    }
  }
`);

  const siteMap = data.allWpMenuItem;

  
  return (
  
    <Styled404Page>
      <SEO title="404: Not found" />
      <div className="not-found__title-wrapper">
        <h1 className="not-found__title">404</h1>
        <h1 className="not-found__title">404</h1>
        <h1 className="not-found__title">404</h1>
        <h1 className="not-found__title">404</h1>
        <h1 className="not-found__title">404</h1>
      </div>
      <h3 className="not-found__subtitle">YIKERS ISLAND</h3>
      <p className="not-found__text">This page does not exist.</p>
      <p className="not-found__text">Try one of these instead: </p>
      {!!siteMap?.nodes && siteMap.nodes !== undefined &&
        <MenuRenderer menuItems={siteMap.nodes} listClass="not-found__page-list" listItemClass="not-found__page-link" />
      }
    </Styled404Page>
  );
}

export default NotFoundPage;
