import styled from 'styled-components';
import { spacing } from '../global/spacing.styles';
import { h1Styles, h3Styles, paragraphStyles } from '../global/typography.styles';
import { colors } from '../global/theme.styles';
import { glitchOne } from '../global/animation.styles';

export const Styled404Page = styled.div`
    padding-top: ${spacing.pageTopPadding}px;
    background-color: ${colors.black};
    color: ${colors.lime};

    .not-found {

        &__title-wrapper {
            position: relative;
            height: 86px;
            width: 100%;
            margin: 40px 0;
            text-align: center;
        }

        &__title {
            ${h1Styles}
            position: absolute;
            width: 100%;
            margin: 0;
            text-align: center;

            &:nth-child(1) {
                clip-path: inset(0px 0px 55px 0px);
            }

            &:nth-child(2) {
                clip-path: inset(31px 0px 48px 0px);
                animation: ${glitchOne} 1s steps(1) 3s infinite;
            }

            &:nth-child(3) {
                clip-path: inset(38px 0px 42px 0px);
            }

            &:nth-child(4) {
                clip-path: inset(44px 0px 31px 0px);
                /* animation: ${glitchOne} 2s steps(1) 2s infinite; */
            }

            &:nth-child(5) {
                clip-path: inset(55px 0px 16px 0px);
            }

            &:nth-child(6) {
                display: none;
            }
        }

        &__subtitle {
            ${h3Styles}
            text-align: center;
        }

        &__text {
            ${paragraphStyles}
            text-align: center;
        }

        &__page-list {
            text-align: center;
            color: ${colors.lime};
            padding-bottom: 25px;
        }

        &__page-link {
            a {
                color: ${colors.lime};
            }
        }

    }
`;
